import React, { Fragment } from 'react';
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Nav, NavItem, NavLink, TabContent, TabPane, Input, Form, Row } from 'reactstrap';
import { Btn, H5 } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { categoryOperations } from '../../state/ducks/category';
import viewIcon from '../../assets/images/datatable/list-details.png';
import { formatDateAndTime } from '../../utils/helper';
import '../../assets/scss/pagination.css';
import Pagination from '../Pagination';
import editIcon from '../../assets/images/datatable/written-paper.png';
import deleteIcon from '../../assets/images/datatable/waste-bin.png';
import reactiveIcon from '../../assets/images/datatable/waste-bin-r.png'; 
import { ToastContainer, toast } from 'react-toastify';
import * as API_URL from "../../env";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      categoryData: [],
      activeData: [],
      inActiveData: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc',
      isOn: true,
    };
  }

  async componentDidMount() {
    try {
      
      // get all Category
      const response = await this.props.listCategory();
      this.setState({ categoryData: response.payload, defaultSearchData: response.payload });
      localStorage.setItem('category', JSON.stringify(response.payload));
      // get active Category
      const pendingResponse = await this.props.listCategory({ "search": "{\"status\":1}" });
      this.setState({ activeData: pendingResponse.payload });
      // get inactive Category
      const processingResponse = await this.props.listCategory({ "search": "{\"status\":0}" });
      this.setState({ inActiveData: processingResponse.payload });

     

    } catch (err) {
      console.log(err);
    }
  }

  generateExcelData = () => {
    const { categoryData } = this.state;
    const xlsxData = [
      ['Category Name', 'Last Edit', 'Question', 'Status']
    ];
    for (const data of categoryData) {
      const dataRow = [
        data.category_name,
        formatDateAndTime(data.last_edit),
        data.question,
        data.status
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, 'category.xlsx');
  };

  handleEditClick = (e) => {
    localStorage.setItem("selectedCategoryId", e.target.getAttribute('data-test-id'));
    window.open("/question-category/category-edit/", "_self");
  }

  

  onUpdate = async (e) => {
    e.preventDefault();
    try {
    const { categoryData } = this.state;
    const categoryId = Number(e.target.getAttribute('data-test-id'));
    const category = categoryData.find(cat => cat.id === categoryId);
    let newStatus = category.status === 'active' ? 'inactive' : 'active';
      let updateResponse = await this.props.updateCategoryStatus({
        id: categoryId, 
        status: newStatus === 'active' ? 1 : 0,
      });
  
       // Update the local state with the new status
    this.setState({
      categoryData: categoryData.map(cat => 
        cat.id === categoryId ? { ...cat, status: newStatus } : cat // Update status of the clicked category
      ),
    });

  
      toast.success(updateResponse.message, { autoClose: 10000 });
      window.open("/question-category/", "_self");
    } catch (err) {
      toast.error(err.response ? err.response.message : 'Update failed', { autoClose: 10000 });
      console.log(err);
    }
  };
  
  
  handleCategoryDetailsClick = (e) => {
    localStorage.setItem("selectedCategoryId", e.target.getAttribute('data-test-id'));
    window.open("/question-category/category-details/", "_self");
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  turnOn = () => {
    this.setState({
      isOn: true // Set isOn to true
    });
  };

  turnOff = () => {
    this.setState({
      isOn: false // Set isOn to false
    });
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    const defaultSearchData = this.state.categoryData.filter(data => {
      const valuesToSearch = Object.values(data).filter(value => value !== null);
      return valuesToSearch.some(value =>
        String(value).toLowerCase().includes(searchData.toLowerCase())
      );
    });
    this.setState({ searchData, defaultSearchData });
  };

  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { BasicTab, defaultSearchData, currentPage, itemsPerPage, sortedColumn, sortOrder, searchData, activeData, inActiveData } = this.state;
    const turnOnButtonStyle = {
      backgroundColor: this.state.isOn ? '#890077' : 'gray',
      color: 'white',
      padding: '7px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight: '10px',
      fontSize: '12px'
    };
    const turnOffButtonStyle = {
      backgroundColor: this.state.isOn ? 'gray' : '#890077',
      color: 'white',
      padding: '7px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '12px'
    };
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Question Category</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Question Category Management</H5>
                  <div className='d-flex align-items-center gap-3'>
                    <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
                    <Link to="/question-category/category-add/">
                      <Btn attrBtn={{ color: 'primary d-flex align-items-center' }}>Add Category</Btn>
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='9' className='mb-2'>
                        <Link to="/question-category/">
                          <button style={turnOnButtonStyle} onClick={this.turnOn} type='button'>Category</button>
                        </Link>
                        <Link to="/question-category/question/">
                          <button style={turnOffButtonStyle} onClick={this.turnOff} type='button'>Question</button>
                        </Link>
                      </Col>
                      <Col md='3'>
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '1' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '1' })}>
                        All Category
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '2' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '2' })}>
                        Active
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '3' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '3' })}>
                        Inactive
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={BasicTab}>
                    <TabPane className='fade show' tabId='1'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th onClick={() => this.handleTableSort('category_name')}>Category Name {sortedColumn === 'category_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('last_edit')}>Last Edit {sortedColumn === 'last_edit' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('question')}>Question {sortedColumn === 'question' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Status {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) =>
                            <tr key={i}>
                              <td>{indexOfFirstItem + i + 1}</td>
                              <td>{data.category_name}</td>
                              <td>{formatDateAndTime(data.last_edit)}</td>
                              <td>{data.question}</td>
                              {data.status === 'active' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>
                                <img src={editIcon} alt='edit' height={14} width={14} data-test-id={data.id} onClick={this.handleEditClick} />
                              </td>
                              <td>                          
                                <img src={data.status === 'active' ? deleteIcon : reactiveIcon}  alt="status icon" height={18} width={18} data-test-id={data.id} onClick={this.onUpdate}/>
                              </td>
                              <td>
                                <img src={viewIcon} alt='view' height={18} width={18} data-test-id={data.id} onClick={this.handleCategoryDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId='2'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th onClick={() => this.handleTableSort('category_name')}>Category Name {sortedColumn === 'category_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('last_edit')}>Last Edit {sortedColumn === 'last_edit' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('question')}>Question {sortedColumn === 'question' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Status {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>Edit</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeData.map((data, i) =>
                            <tr key={i}>
                              <td>{indexOfFirstItem + i + 1}</td>
                              <td>{data.category_name}</td>
                              <td>{formatDateAndTime(data.last_edit)}</td>
                              <td>{data.question}</td>
                              {data.status === 'active' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>
                                <img src={editIcon} alt='edit' height={14} width={14} data-test-id={data.id} onClick={this.handleEditClick} />
                              </td>
                              <td>
                                <img src={viewIcon} alt='view' height={18} width={18} data-test-id={data.id} onClick={this.handleCategoryDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId='3'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th onClick={() => this.handleTableSort('category_name')}>Category Name {sortedColumn === 'category_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('last_edit')}>Last Edit {sortedColumn === 'last_edit' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('question')}>Question {sortedColumn === 'question' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Status {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>Edit</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {inActiveData.map((data, i) =>
                            <tr key={i}>
                              <td>{indexOfFirstItem + i + 1}</td>
                              <td>{data.category_name}</td>
                              <td>{formatDateAndTime(data.last_edit)}</td>
                              <td>{data.question}</td>
                              {data.status === 'active' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>
                                <img src={editIcon} alt='edit' height={14} width={14} data-test-id={data.id} onClick={this.handleEditClick} />
                              </td>
                              <td>
                                <img src={viewIcon} alt='view' height={18} width={18} data-test-id={data.id} onClick={this.handleCategoryDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <CardFooter>
                  <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  listCategory: categoryOperations.listCategory,
  updateCategoryStatus: categoryOperations.updateCategoryStatus,

};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
import React, { Fragment } from 'react';
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Container, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, TabContent, TabPane, Input, Form, Row, CardFooter } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import * as API_URL from "../../env.js";
import request from "../../utils/request";
import Pagination from '../Pagination';
import '../../assets/scss/pagination.css';
import { formatDateAndTime } from '../../utils/helper';
import product from '../../assets/images/datatable/product.png';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      selectedData: [],
      selectedStoryBookData: [],
      selectedAddOnData: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc'
    };
  }

  async componentDidMount() {
    // get all product
    const options = {
      url: API_URL.BACKEND_SERVICE_API_URL + `/product/list-products`,
      method: "POST",
      headers: {
        authorization: localStorage.getItem('token')
      }
    };
    let response = await request(options);
    this.setState({ selectedData: response.payload, defaultSearchData: response.payload });
    // get Story Book product
    const optionStory = {
      url: API_URL.BACKEND_SERVICE_API_URL + `/product/list-products`,
      method: "POST",
      headers: {
        authorization: localStorage.getItem('token')
      }
    };
    let responseStory = await request(optionStory);
    this.setState({ selectedStoryBookData: responseStory.payload });
    // get Add On product
    const optionAddOn = {
      url: API_URL.BACKEND_SERVICE_API_URL + `/product/list-products`,
      method: "POST",
      headers: {
        authorization: localStorage.getItem('token')
      }
    };
    let responseAddOn = await request(optionAddOn);
    this.setState({ selectedAddOnData: responseAddOn.payload });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    const defaultSearchData = this.state.selectedData.filter(data => {
      const valuesToSearch = Object.values(data).filter(value => value !== null);
      return valuesToSearch.some(value =>
        String(value).toLowerCase().includes(searchData.toLowerCase())
      );
    });
    this.setState({ searchData, defaultSearchData });
  };

  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { BasicTab, defaultSearchData, selectedStoryBookData, selectedAddOnData, currentPage, itemsPerPage, sortedColumn, sortOrder, searchData } = this.state;
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Product Listing</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Product Listing</H5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='9'></Col>
                      <Col md='3'>
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '1' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '1' })}>
                        All
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '2' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '2' })}>
                        Story Book
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '3' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '3' })}>
                        Add On
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <TabContent activeTab={BasicTab}>
                    <TabPane className='fade show' tabId='1'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('sku')}>SKU Number {sortedColumn === 'sku' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>Product</th>
                            <th onClick={() => this.handleTableSort('type')}>Product Type {sortedColumn === 'type' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('itemName')}>Product Title {sortedColumn === 'itemName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('price')}>Price {sortedColumn === 'price' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.product_id}</td>
                              <td><img src={product} alt='edit' height={25} width={25} /></td>
                              {data.type === 'Add-on' ? <td><span className='badge badge-light-info'>{data.product_type}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.product_type}</span></td>}
                              <td>{data.product_name}</td>
                              <td>{data.product_price}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId='2'>
                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('sku')}>SKU Number {sortedColumn === 'sku' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>Product</th>
                            <th onClick={() => this.handleTableSort('type')}>Product Type {sortedColumn === 'type' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('itemName')}>Product Title {sortedColumn === 'itemName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('price')}>Price {sortedColumn === 'price' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('date')}>Last Updated Date {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.sku}</td>
                              <td><img src={product} alt='edit' height={25} width={25} /></td>
                              {data.type === 'Add-on' ? <td><span className='badge badge-light-info'>{data.type}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.type}</span></td>}
                              <td>{data.itemName}</td>
                              <td>{data.price}</td>
                              <td>{data.date}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId='3'>
                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('sku')}>SKU Number {sortedColumn === 'sku' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>Product</th>
                            <th onClick={() => this.handleTableSort('type')}>Product Type {sortedColumn === 'type' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('itemName')}>Product Title {sortedColumn === 'itemName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('price')}>Price {sortedColumn === 'price' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('date')}>Last Updated Date {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.sku}</td>
                              <td><img src={product} alt='edit' height={25} width={25} /></td>
                              {data.type === 'Add-on' ? <td><span className='badge badge-light-info'>{data.type}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.type}</span></td>}
                              <td>{data.itemName}</td>
                              <td>{data.price}</td>
                              <td>{data.date}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <CardFooter>
                  <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Product;

import * as types from "./types";
import * as API_URL from "../../../env";

export const listCategory = (payload) => ({
    type: types.FETCH_CATEGORY_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/category/list-category",
        method: "POST",
        body: payload
    },
});

export const addCategory = (payload) => ({
    type: types.INSERT_CATEGORY,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/category/add-category",
        method: "POST",
        body: payload
    },
});

export const updateCategory = (category) => ({
    type: types.UPDATE_CATEGORY,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/category/update-category",
        method: "PUT",
        body: category
    },
});
export const updateCategoryStatus = (category) => ({
    type: types.UPDATE_CATEGORY,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/category/update-category-status",
        method: "PUT",
        body: category
    },
});

export const listCategoryDetails = (payload) => ({
    type: types.FETCH_CATEGORY_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/category/get-Category-details",
        method: "POST",
        body: payload
    },
});

export const updateCategoryNote = (category) => ({
    type: types.UPDATE_CATEGORY,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/category/update-category-note",
        method: "PUT",
        body: category
    },
});